import React from "react"
import { graphql } from "gatsby"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

import Layout from "../components/layout"
import TwoColumnFeature from '../components/global/TwoColumnFeature';
import FixedContainer from '../components/global/FixedContainer';
import CallToAction from '../components/global/CallToAction';
import SEO from "../components/seo"


const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
    mb-8 font-display text-center inline-block font-black text-4xl border-lightBlue relative`
  }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    bottom:0;
    left: 0;
  }
`;

const Redactor = styled.div`
  & p{
    ${tw` mb-4 `}
  }
`;

const Spacer = styled.div`
  ${tw`
    my-12
  `}
`;
export const query = graphql`
  query IssueTemplateQuery{
    issue: issuesJson {
      data {
        title
        text
        callout
        keypoint
        slug
        image {
          url
          alt
        }
      }
    }
    donate: markdownRemark(frontmatter: {slug: {eq: "/issues/donate"}}) {
      html
      frontmatter {
        heading
      }
    }
  }
`;


const IssueTemplate = ({data, errors, pageContext}) => {
  const slug = pageContext.slug;

  const matchedIssue = data.issue.data.find(issue => issue.slug === slug);
  return (
    <Layout>
      <SEO title={matchedIssue.title} />
      {/* <IssueText body={matchedIssue.text} keypoints={matchedIssue.keypoint} subheading={"Issues"} heading={matchedIssue.title} /> */}
      <TwoColumnFeature heading={matchedIssue.title} subheading={`Issues`} content={matchedIssue.callout} image={matchedIssue.image} />
      <Spacer>
        <FixedContainer bg={true}>
          <StyledH2>Where Joel Stands</StyledH2>
          <Redactor dangerouslySetInnerHTML={{__html: matchedIssue.text}} />
        </FixedContainer>
      </Spacer>

      <CallToAction />
      {/* <TextColumn text={data.donate.html} heading={data.donate.frontmatter.heading} multicolumn={false} /> */}
    </Layout>
  )
}

export default IssueTemplate
