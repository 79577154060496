import React from "react"
import PropTypes from "prop-types"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';


 const Wrapper = styled.div`
  ${tw`
    bg-lightBlue
  `}
 `;

const Subheading = styled.span`
  ${tw`
    block my-0 font-display text-darkBlue font-light text-3xl
  `}
  letter-spacing: .05rem;
  margin-bottom: -1rem;
`;

const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
      font-display font-black text-white text-4xl border-darkBlue max-w-xs relative mt-0`
    }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #ee1b24;
    bottom:0;
    left: 0;
  }
`;

 const ContentWrapper = styled.div`
  ${tw`
    text-2xl
    py-16 px-8 md:px-16 text-darkBlue 
    md:text-3xl
  `}
 `;

 const Content = styled.blockquote`
    ${tw`my-4`}
    letter-spacing: -0.03rem;
    line-height: 1.4;
    text-indent: 1.5rem;
 `;

const TwoColumnFeature = ({heading, subheading, content, image}) => (
  <Wrapper 
    className={`flex flex-col
      md:grid md:grid-cols-2 md:items-center
    `}
  >
    <ContentWrapper>
      <Subheading>{subheading}</Subheading>
      <StyledH2>{heading}</StyledH2>
      <Content>
        {content}
      </Content>
      <p className={`text-right text-2xl`}>- Joel O'Dorisio</p>
    </ContentWrapper>
    <img src={image.url} alt={image.alt} />

  </Wrapper>
);

TwoColumnFeature.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  htmlContent: PropTypes.string,
  image: PropTypes.object
}

TwoColumnFeature.defaultProps = {
  heading: 'Main Heading',
  subheading: 'Subheading',
  content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  image: {
    alt: `Image alt text.`,
    url: `https://d2u86e95w5oqta.cloudfront.net/images/woman-in-red-sweater-using-macbook-air-3770612.jpg?mtime=20200524105954&focal=none`
  }
}


export default TwoColumnFeature
