import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

 const Wrapper = styled.section`
  ${tw`
    bg-gray-100 pb-12
  `}
 `;

 const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
      text-center inline-block mx-auto font-display font-black text-4xl border-lightBlue max-w-xs relative mt-0 mb-16 px-4`
    }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    /* border-bottom: 8px solid #00adef; */
    bottom:0;
    left: 0;
  }
 `;

const CtaWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  align-items:start;
  justify-items:center;
  justify-content:center;
  grid-gap: 2rem;

  @media (min-width:1280px){
    display: flex;
    justify-content:center;
  }
`;

const Cta = styled.article`
  ${tw`
    mx-8 flex flex-col justify-center items-center relative
  `}

  & a{
    ${tw`
      mt-8 font-bold text-xl
    `}
  }

  &:hover{
    &>div{
      box-shadow:  -20px -20px 60px #d2d5d6, 
             20px 20px 60px #ffffff;
    }
  }
`;

const Icon = styled.div`
  ${tw`
    p-8 block mx-auto
  `}
  width: auto;  
  border-radius: 50%;
  background: #f7fafc;
  box-shadow:  20px 20px 60px #d2d5d6, 
              -20px -20px 60px #ffffff;
  
  & img{
    width: 4rem;
    height: 4rem;
  }
`;


const CallToAction = () => {
  const data = useStaticQuery(graphql`
    query {
      ctas:  globalsJson {
        callToActionItems {
          link
          text
          image {
            alt
            url
          }
        }
      }
    }
  `)

  return (
      <Wrapper>
        <div className={`container mx-auto text-center`}>
          <StyledH2>Get Involved</StyledH2>
          <CtaWrapper>
            {data.ctas.callToActionItems.map((cta, index) => (
              <Cta>
                <Icon>
                  <img src={cta.image.url} alt={cta.image.alt}/>
                </Icon>
                <a href={cta.link} className={`block stretched-link`}>{cta.text}</a>
              </Cta>
              
            ))}
          </CtaWrapper>
        </div>
      </Wrapper>
      
    
  )
}

export default CallToAction
