import React from "react"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

const Container = styled.div`
  ${tw`
    mx-4
     lg:mx-auto 
  `}
  max-width: 1024px;
`;



const FixedContainer = ({bg, padding = true, children}) => {
  const background = bg ? "border border-gray-200 bg-white" : "";
  const paddingStyle = padding ? "p-8 lg:p-12" : ""
  return (
      <Container className={background && paddingStyle}>
        {children}
      </Container>
  )
}

export default FixedContainer
